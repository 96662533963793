import { TrophyIcon } from '@heroicons/react/24/outline'
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchwallet } from '../reducers/actions';


function PeriodCountdown() {
  const loadBalance= async()=>{
    
    const token= localStorage.getItem('token')
    const defaultHead={
      headers: {
          'Authorization': `Bearer ${token}`
      }
    };
    const baseUrl= 'https://backend.royale98.in/api/v1';
    const response = await axios.get(baseUrl+"/me",defaultHead);
            
    dispatch({ type: 'SET_USER_WALLET', payload: response.data.wallet });
  }

    const [currentTime, setCurrentTime] = useState("0");
    const [timeInSeconds,setTimeInSeconds]=useState("0:00");
    const [nextId,setNextId]=useState("0");
    const bidType = useSelector(state=>state.auth.bidType);
  
    const game= useSelector(state=> state.auth.currentGame.toLowerCase());  
    const games= useSelector(state=>state.auth.games);

    const dispatch = useDispatch();
    const fetchData = async () => {
      const baseUrl= 'https://backend.royale98.in/api/v1';
      const token=localStorage.getItem('token')??'0';
      const defaultHead={
        headers: {
            'Authorization': `Bearer ${token}`
        }
      };
        try { 
            const response = await axios.get(baseUrl+"/get-game-data",defaultHead);
            // Dispatch data to the store 
            dispatch({type:'SET_GAME_DATA',payload:response.data});
        } catch (error) {
            // Handle errors
             
        } finally {
            
        }
    };

    useEffect(() => {
      if (games.length > 0) {
        setNextId(parseInt(games[0].game_id ?? 0) + 1);
        const interval = setInterval(() => {
          const now = new Date();
          const lastGameTime = games[0].created_at;
          const futureTime = new Date(lastGameTime.replace(' ', 'T'));
          futureTime.setMinutes(futureTime.getMinutes() + 3);
    
          const diffInMillis = futureTime - now;
          const diffInSeconds = Math.floor(diffInMillis / 1000);
          const diffInMinutes = String(Math.floor(diffInSeconds / 60)).padStart(2, '0');
          const remainingSeconds = String(diffInSeconds % 60).padStart(2, '0');
          if(diffInSeconds<31){
            if(bidType!=false){
              dispatch({type:'SET_BID_TYPE',payload:false});
            }
            
          }
          else if(diffInSeconds<1){
            fetchwallet()
          }
          else{
            if(bidType!=true){
              dispatch({type:'SET_BID_TYPE',payload:true});
            }
          }
          if(diffInSeconds>174){
             loadBalance()
             console.log("hitting api")
          }
          if (diffInSeconds < 2) {
            fetchData(); // Fetch new data when the countdown reaches zero
          } 
          if(diffInSeconds<1){
            setTimeInSeconds("00:00");
          }else{
            setTimeInSeconds(diffInMinutes + ":" + remainingSeconds);
          } 
        }, 1000); // Update every second
    
        return () => clearInterval(interval); // Clean up interval on unmount
      }
    }, [games]); // Only run this effect when `games` changes
    
   
  return (
    <div className='period__countdown'>
        <ul>
            <li className='row row-start align-center'> <span><TrophyIcon style={{fontSize:'15px',height:'20px',width:'20px'}}/></span> Period</li>
            <li>Count Down</li>
        </ul>
        <ul>
            <li> 
                {nextId}
            </li>
            <li>
               {timeInSeconds}
            </li>
        </ul>
    </div>
  )
}

export default PeriodCountdown