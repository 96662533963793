import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthHeader from '../../components/AuthHeader';
import Input from '../../components/Input';
import Button from '../../components/Button';
import toast from 'react-hot-toast';
import { enterUtr } from '../../reducers/bank-action';
import img from '../../images/recharge-help.jpeg'

function UTRInput() {
 
  const navigate = useNavigate();
  const { utrcode } = useParams();
  const [token] = useState(localStorage.getItem('token'));
  const [utr, setUtr] = useState('');
  const [balance, setBalance] = useState(30); // Assume initial balance

  useEffect(() => {
    if (!token) {
      toast.error("You need to be logged in to access this page.");
      navigate('/login'); // Redirect to login page
    }
  }, [token, navigate]);

  const handleUtrChange = (e) => {
    setUtr(e.target.value);
  };

  const handleSubmit = async () => {
    if (!utr) {
      toast.error("Please enter your UTR number.");
      return;
    }

    try {
      const response = await enterUtr(token, {
        user_id: 1, // Replace with actual user ID
        utr_code: utrcode, // Use the UTR code from the URL params
        utr_id: utr, // Use the UTR input from the state
      });

      if (response.success) {
        toast.success(response.message);
        navigate(`/recharge-check/${utrcode}`);
      } else {
        toast.error("Submission failed: " + response.message);
      }
    } catch (error) {
      console.error("Error during UTR submission:", error);
      toast.error("An error occurred while processing your request.");
    }
  };

  return (
    <>
      <AuthHeader pageTitle="Submit UTR" />
       
      <div style={{ background: '#fff', marginTop: '10px', paddingBlock: '20px', paddingInline: '10px', borderBottom: '3px solid #fe2379' }}>
        <h4 style={{ textAlign: 'center' }}>Enter UTR Number</h4>
      </div>

      <div style={{ marginTop: '20px' }}>
        <Input
          type="text"
          placeholder="Enter UTR Number"
          value={utr}
          onChange={handleUtrChange}
          prepend={<img src='https://cdn-icons-png.flaticon.com/128/1883/1883462.png' style={{ height: '20px', width: '20px' }} />}
        />
      </div>
      <div style={{ background: '#fff', padding: '5px 30px', marginTop: '5px' }}>
        <p style={{ textAlign: 'justify', fontSize: '12px', color: 'gray' }}>
          Tips: Please ensure the UTR number is correct before submitting.
        </p>
      </div>
      <br />
      <Button btnClass="btn-primary" label="Submit UTR" clickTo={handleSubmit} />
      <br/>
      <div style={{textAlign:'center'}}>
      <img src={img} style={{width:'100%',margin:'auto'}}/>

      </div>          </>
  );
}

export default UTRInput;
