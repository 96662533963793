import { CodeBracketIcon, ShieldCheckIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react' 
import GuestHeader from '../../components/GuestHeader'
import Input from '../../components/Input'
import { DevicePhoneMobileIcon, LockClosedIcon, PhoneArrowDownLeftIcon, PhoneIcon, UserIcon } from '@heroicons/react/24/outline'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import AuthHeader from '../../components/AuthHeader'
import toast from 'react-hot-toast'

function ResetPassword() {
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(30);
  useEffect(() => {
    let interval = null;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setOtpSent(false);
      setTimer(30); // Reset timer for the next OTP request
    }
    return () => clearInterval(interval);
  }, [otpSent, timer]);
  const sendOtp = async () => {
     
      toast.error('Please Enter a Valid Mobile Number');
     
  };
  
  return (
    <>
    <AuthHeader pageTitle="Account Security"/>
    <br/>
    <Input prepend={"+91" } placeholder="Enter Mobile Number"/>
    <br/>
    <Input prepend={<LockClosedIcon /> } placeholder="Enter New Password"/>
    <br/>
    <Input prepend={<LockClosedIcon /> } placeholder="Confirm New Password"/>
    <br/>
    
    <Input
            prepend={<ShieldCheckIcon />}
            placeholder="Verification OTP"
            type="number" 
            // onChange={(e) => setVerificationCode(e.target.value)}
            trailing={
              otpSent ? (
                <Button
                  btnClass="btn-disabled"
                  
                  label={`Wait (${timer}s)`}
                  disabled
                />
              ) : (
                <Button btnClass="btn-primary-outline f-12 border-0"  type="button" label="Send OTP" clickTo={()=>sendOtp()} />
              )
            }
          />
    <br/>
     
    <Button label="Reset Password" btnClass="btn-primary"/>
    <br/>
     
    </>
  )
}

export default ResetPassword