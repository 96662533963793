import React, { useEffect, useState } from 'react';
import GuestHeader from '../../components/GuestHeader';
import AuthHeader from '../../components/AuthHeader';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { addWithdrawRequest, fetchBankCards, changeWithdrawPassword } from '../../reducers/bank-action';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ListAlt } from '@mui/icons-material';

function Withdrawal() {
  const amounts = [100, 300, 500, 1000, 2000, 5000];
  const [loading, setLoading] = useState(true);
  const rechargeOptions = ['Seppay', 'Wonpay', 'Matrixpay'];
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const bank = useSelector(state => state.auth.bank);
  const [avAccounts, setAvAccounts] = useState([]);
  const [withamount, setWithamount] = useState(0);
  const [withpassword, setWithpassword] = useState('');
  const [acId, setAcId] = useState(null);

  // State for modal visibility and password change form
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const banks = await fetchBankCards(localStorage.getItem('token'));
        dispatch({ type: 'BANK', payload: banks });
      } catch (error) {
        console.error("Failed to fetch bank cards:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const submitWithdrawForm = async () => {
    if (acId == null || acId === '') {
      toast.error('Please Select an account');
    } else if (withamount < 1 || user.wallet < withamount) {
      toast.error('Withdraw Amount Error');
    } else if (withpassword === '') {
      toast.error('Withdraw Password Required');
    } else {
      const res = await addWithdrawRequest(localStorage.getItem('token'), { payout_id: acId, withdraw_password: withpassword, amount: withamount });
      if (res.response === true) {
        toast.success(res.message);
        setAcId(null);
        setWithamount(0);
        setWithpassword('');
        window.location.href = '/me';
      } else {
        toast.error(res.message);
      }
    }
  };

  // Function to handle the password change submission
  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    
    try {
      // const res = await changeWithdrawPassword(localStorage.getItem('token'), { currentPassword, newPassword });
      // if (res.success) {
      //   toast.success("Password changed successfully!");
      //   setIsModalOpen(false);
      // } else {
      //   toast.error(res.message);
      // }
    } catch (error) {
      toast.error("Failed to change password");
    }
  };

  return (
    <>
      <AuthHeader pageTitle="Withdrawal" trailing={<Link to="/withdraw/history"><ListAlt /></Link>} />
      <div style={{ background: '#fff', marginTop: '2px', paddingBlock: '20px', paddingInline: '10px' }}>
        <h4>Balance: {user.wallet}</h4>
      </div>

      {bank !== null && (
        <>
          {bank.length < 1 ? (
            <div style={{ backgroundColor: '#fff', marginInline: '20px', padding: '50px 20px', textAlign: 'center', marginTop: '20px', borderRadius: '20px', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px' }}>
              <Link to="/bank-card">Add Card</Link>
              <p>Please Add Bank Card</p>
              <img src='https://cdn-icons-png.flaticon.com/128/17114/17114715.png' alt="Add Bank Card" />
            </div>
          ) : (
            !loading && (
              <>
                <div style={{ background: '#fff', marginTop: '20px', paddingBlock: '10px', paddingInline: '10px', marginInline: '20px', borderRadius: '10px', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                  <Input
                    type="number"
                    placeholder="Enter Amount"
                    value={withamount}
                    onChange={(e) => e.target.value > 0 ? setWithamount(parseInt(e.target.value)) : setWithamount(0)}
                    prepend={<img src='https://cdn-icons-png.flaticon.com/128/9382/9382295.png' style={{ height: '20px', width: '20px' }} alt="Amount" />}
                  />
                  <span style={{ fontSize: '10px', color: 'gray', marginInline: '20px' }}>Fee 0 to account 9700</span>
                </div>

                <div className='withdraw-selects'>
                  <ul>
                    <li>Choose to pay</li>
                    <li>
                      <select onChange={(e) => e.target.value === "" ? setAvAccounts([]) : setAvAccounts(e.target.value === "1" ? bank.filter((bank) => bank.upi != null) : bank.filter((bank) => bank.upi == null))}>
                        <option value="">Select Type</option>
                        <option value="0">Bank Check Out</option>
                        <option value="1">UPI Check Out</option>
                      </select>
                    </li>
                  </ul>
                  <ul>
                    <li>Select Account</li>
                    <li>
                      <select onChange={(e) => setAcId(e.target.value)}>
                        <option value="">Select Account</option>
                        {avAccounts.map((e) => <option key={e.id} value={e.id}>{e.upi !== null ? '(Upi)' : ''}{e.bank_name}</option>)}
                      </select>
                    </li>
                  </ul>
                </div>
                {avAccounts.length < 1 && <p style={{ textAlign: 'center', color: 'orange', fontSize: '13px' }}>No Accounts Available</p>}
                <div className='withdraw-selects'>
                  <p style={{ fontSize: '14px' }}>Enter Withdraw Password</p>
                </div>
                <div style={{ marginInline: '20px' }}>
                  <Input
                    type="text"
                    value={withpassword}
                    onChange={(e) => setWithpassword(e.target.value)}
                    placeholder="Withdraw Password"
                    prepend={<LockClosedIcon />}
                  />
                </div>
                <br />
                <br />
                <Button btnClass="btn-primary" clickTo={() => submitWithdrawForm()} label="Withdraw" />
                <br />
                <Button btnClass="btn-primary-outline w-100" label="Change Withdraw Password" clickTo={() => setIsModalOpen(true)} />
              </>
            )
          )}
        </>
      )}

      {/* Modal for Changing Withdraw Password */}
      {isModalOpen && (
        <div className="modal-container">
          <div className="modal-box" style={{padding:'20px'}}>
            <h3>Change Withdraw Password</h3>
            <Input
              type="password"
              placeholder="Current Password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <Input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <Input
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button btnClass="btn-primary" clickTo={handleChangePassword} label="Submit" />
            <Button btnClass="btn-secondary" clickTo={() => setIsModalOpen(false)} label="Close" />
          </div>
        </div>
      )}
    </>
  );
}

export default Withdrawal;
