import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchwallet } from '../reducers/actions';
import { loadWallet } from '../reducers/bank-action';

function GuessButton() {
  const dispatch = useDispatch();
  const colorButtons=['Green','Voilet','Red'];
  const numberButtons=[0,1,2,3,4,5,6,7,8,9];
  const game= useSelector(state=> state.auth.currentGame.toLowerCase());  
  const bidType = useSelector(state=>state.auth.bidType);

  
  const [modalOpen,setModalOpen]=useState(false);
  const [modalHeader,setModalHeader]=useState("Modal Header");
  const [contractMoney,setContractMoney]=useState(10);
  const [contractToken,setTokenContractToken]=useState(1);
  const [totalMoney,setTotalMoney]=useState(10);
  const [type,setType]=useState('');

  const loadBalance= async()=>{
    
    const token= localStorage.getItem('token')
    const defaultHead={
      headers: {
          'Authorization': `Bearer ${token}`
      }
    };
    const baseUrl= 'https://backend.royale98.in/api/v1';
    const response = await axios.get(baseUrl+"/me",defaultHead);
            
    dispatch({ type: 'SET_USER_WALLET', payload: response.data.wallet });
  }

  const openModal=(header,sel)=>{
    setModalOpen(true);
    setModalHeader(header);
    setType(sel);
  }

  const changeToken=(type,token)=>{
    if(type=='-'){
      if(token==1){return}
      setTokenContractToken(token-1)
    }else{
      setTokenContractToken(token+1);
    }
    
  }
  
  useEffect(()=>{
    setTotalMoney(contractToken*contractMoney);
  },[contractToken,contractMoney]);

  const loadSelections= async()=>{
    const baseUrl= 'https://backend.royale98.in/api/v1';
    const token=localStorage.getItem('token')??'0';
          const defaultHead={
            headers: {
                'Authorization': `Bearer ${token}`
            }
          };
    const response3 = await axios.get(baseUrl+"/get-selections",defaultHead);
    
                // Dispatch data to the store
                dispatch({type:'SET_ORDERS',payload:response3.data}); 
  }

  const postData = async () => {
    const baseUrl= 'https://backend.royale98.in/api/v1/add-selections';
    const token = localStorage.getItem('token') || 'defaultToken';
    
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json' // Set content type as needed
    };
    
    const data = {
      token: contractToken,
      amount: contractMoney*contractToken,
      selection:type,
      type:game
      // Add other data fields as required
    };
  
    try {
      const response = await axios.post(baseUrl, data, { headers });
      console.log('Response Data:', response.data);
      if(response.data.response){
        toast.success(response.data.message);
        loadBalance()
        setModalOpen(false)
        loadSelections()
      }else{
        toast.error(response.data.message);
      } 
      // Handle success
    } catch (error) {
      console.error('Error posting data:', error);
      toast.error(JSON.stringify(error));
      // Handle error
    }
  };

  useEffect(()=>{
    if(bidType==false){
      setModalOpen(false);
    }
   
  },[bidType])
  
  
  
  return (
    <> 
    {modalOpen==true && 
    <div className='modal-container'>
      <div className='modal-box'>
         <div className={`modal-header ${modalHeader.toLocaleLowerCase().replace(" ","-")}`}>
           {modalHeader}          
         </div>
         <div className='modal-body'>
          <p>Contract Money</p>
          <ul className='contractMoneySelect'>
            <li onClick={()=>setContractMoney(10)} className={contractMoney==10?`active`:``}>10</li>
            <li onClick={()=>setContractMoney(100)} className={contractMoney==100?`active`:``}>100</li>
            <li onClick={()=>setContractMoney(1000)} className={contractMoney==1000?`active`:``}>1000</li>
            <li onClick={()=>setContractMoney(10000)} className={contractMoney==10000?`active`:``}>10000</li>
          </ul>
           
          <ul className='row row-start align-center' style={{marginTop:'30px'}}>
            <li>
                <p>Number</p>
            </li>
            <li>
              <ul className='tokenSelect'>
                <li onClick={()=>changeToken('-',contractToken)}>-</li>
                <li>{contractToken}</li>
                <li onClick={()=>changeToken('+',contractToken)}>+</li>
              </ul>
            </li>
          </ul>
          <p style={{marginTop:'20px'}}>Total Contract Money is {totalMoney}</p>
          <ul className='row row-start align-center' style={{marginTop:'10px'}}>
            <li>
              <input type='checkbox' checked/>

            </li>
            <li>
              I agree Presale rule
            </li>
          </ul>
         
         
         </div>
         <div className='modal-footer'>
          <ul>
            <li onClick={()=>setModalOpen(false)}>
              Close 
            </li>
            <li onClick={()=>postData()}>
              Confirm
            </li>
          </ul>
         </div>
      </div>
    </div>
    }
    <div className='guess__buttons'>
        <ul>
         {colorButtons.map((clrbtn)=><li><button className={`btn btn-color btn-${clrbtn.toLowerCase()} ${(bidType !=true)?`btn-disabled`:``}`} onClick={(bidType !=true)?null:()=> openModal("Join "+clrbtn,clrbtn) }>Join {clrbtn}</button></li>)}
        </ul>
        <ul>
         {numberButtons.map((nmbrbtn)=><li><button className={`btn btn-number btn-color btn-${nmbrbtn.toString()} ${(bidType !=true)?`btn-disabled`:``}`} onClick={(bidType !=true)?null:()=> openModal("Join "+nmbrbtn,nmbrbtn)}>{nmbrbtn}</button></li>)}
        </ul>
         
    </div>
    </>
  )
}

export default GuessButton