import React, { useState } from 'react';
import GuestHeader from '../../components/GuestHeader';
import Input from '../../components/Input';
import { DevicePhoneMobileIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import toast, { LoaderIcon } from 'react-hot-toast';
import axios from 'axios'; // Ensure you have axios installed
import { useDispatch } from 'react-redux';

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();

  const handleLogin = async () => {
    setLoading(true); 

    try {
      const response = await axios.post('https://backend.royale98.in/api/v1/login', {
        mobile: mobileNumber,
        password: password
      });

      if (response.data.response === true) {
        localStorage.setItem('token', response.data.token);
        
        dispatch({ type: 'SET_USER_DATA', payload: response.data.user });
        dispatch({ type: 'LOGIN'});
        navigate('/guess');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  const toRegister = () => {
    navigate('/register');
  };

  const toForgetPassword = () => {
    navigate('/forget-password');
  };

  return (
    <>
      <GuestHeader pageTitle="Login" />
      <br />
      <div style={{ padding: '15px', maxWidth: '500px', margin: 'auto' }}>
      <Input
        prepend={<ul className='row row-start'><li><DevicePhoneMobileIcon /></li><li>+91</li></ul>}
        placeholder="Enter Mobile Number"
        value={mobileNumber}
        onChange={(e) => setMobileNumber(e.target.value)}
      />
      <br />
      <Input
        prepend={<LockClosedIcon />}
        placeholder="Enter Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <br />
      <Input type="checkbox" label='Remember Me' />
      <br />
      <Button clickTo={handleLogin} label={"Login"} btnClass="btn-primary" disabled={loading} />
      <br />
      <ul className='row row-between w-90'>
        <li>
          <Button label={"Register"} btnClass="btn-primary-outline" clickTo={toRegister} custom={true} />
        </li>
        <li>
          <Button label="Forget Password" btnClass="btn-primary-outline" clickTo={toForgetPassword} custom={true} />
        </li>
      </ul>
      </div>
    </>
  );
}

export default Login;
