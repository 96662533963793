import { TrophyIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import Accordion from './Accordian';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function GameOrderRecord() {
  const game = useSelector(state => state.auth.currentGame);  
  const records = useSelector(state => state.auth.records);
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15; // Set the number of items per page
  
  // Filter the records based on the current game
  const filteredRecords = records.filter((e) => e.in_game === game.toString().toLowerCase());
  
  // Calculate the current items for the page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredRecords.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredRecords.length / itemsPerPage);

  // Pagination handlers
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className='gameresult__record'> 
      <div className='heading'>
        <span><TrophyIcon style={{ height: '20px', width: '20px' }} /></span> {game} Order Result
      </div>

      {currentItems.map((gr) => (
        <Accordion 
          key={gr.game_id} 
          data={{
            "Period": gr.game_id,
            "Contract Money": parseInt(gr.amount / gr.token),
            "Contract Count": gr.token,
            "Delivery": gr.amount * 0.98,
            "Fee": gr.amount * 0.02,
            "Select": gr.selection,
            "Status": gr.status == 1 ? 'Win' : gr.status == 0 ? 'Wait' : 'Lose',
            "Amount": gr.amount * 0.98,
            "Create time": gr.created_at
          }} 
          title={gr.game_id} 
          select={gr.selection} 
          result={"aa"} 
          dt={gr}
        >
          <p>This is the content for section {gr.game_id}.</p>
        </Accordion>
      ))}

      {/* Pagination controls */}
      <ul className='row row-between' style={{ paddingBlock: '5px' }}>
        <li style={{ height: '20px', width: '20px', cursor: 'pointer' }} onClick={handlePrev}>
          <ChevronLeftIcon />
        </li>
        <li>
          <span>{currentPage}</span>/<span>{totalPages}</span>
        </li>
        <li style={{ height: '20px', width: '20px', cursor: 'pointer' }} onClick={handleNext}>
          <ChevronRightIcon />
        </li>
      </ul>
      
      <br/><br/><br/><br/><br/><br/><br/>
    </div>
  );
}

export default GameOrderRecord;
