import React, { useEffect, useState } from 'react';
import GuestHeader from '../../components/GuestHeader';
import AuthHeader from '../../components/AuthHeader';
import Input from '../../components/Input';
import Button from '../../components/Button';
import TextArea from '../../components/TextArea'; // Assuming you have a TextArea component
import { useDispatch, useSelector } from 'react-redux';
import { loadReport, loadTeam, loadTransactions } from '../../reducers/bank-action';

function RechargeHistory() {
  const transactions = useSelector(state=>state.auth.transactions);
  const dispatch = useDispatch(); 
  
  useEffect(()=>{
    const fetchData = async () => {
  const report = await loadTransactions(localStorage.getItem('token'));
   dispatch({type:'TRANSACTIONS',payload:report  });
    }
    fetchData();
  },[])
  const reportCards = [
    'Subordinates',
    'Today Registrations',
    'Today bet members',
    'Today Login members',
    'Today fist recharge',
    'Total recharge amount',
    'Today first-recharge members',
    'Total withdrawl amount'
  ];


  

  return (
    <>
      <AuthHeader pageTitle="Recharge Record" />
      <div className='transactions'>
        {(transactions==null || transactions.recharges.length<1) &&
        <div className='flex-col h-40'>
          Recharges List Will Appear Here
          </div>
        }
        {transactions != null &&
        <>
        {transactions.recharges.map((e)=> 
        <>
        <ul>
          <li style={{fontSize:'12px'}}>
          Recharge
          <span className={e.status=='success'?`badge badge-success`:e.status=='failed'?`badge badge-failed`:`badge badge-processing`}> {e.status}</span>
          </li>
        <li>
          {e.amount}
        </li>
        

        </ul>
        </>
        )} </>
        }
      </div>
     </>
  );
}

export default RechargeHistory;
